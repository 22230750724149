<template>
  <div class="container">
    <div class="page-header">
      <img alt="iPOS logo" src="../assets/iPosCafe-Logo.png">
      <h2>iPOS Cafe</h2>
    </div>
    <hr>
    <h3>Thank you - You Have Signed Out</h3>
    <b-button style="margin-top:1em;" @click="goLogin()">Sign In</b-button>
    <hr>
    <a href="https://www.newcare.international"  target="_blank">Built with <i class="fa fa-heart" style="color:red;"/> for you by us at Newcare International (an IOIHAN company)</a>
  </div>
</template>

<script>
export default {
  name: 'signout',
  mounted () {
    this.$root.logout();
  },
  methods: {
    goLogin () {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style lang="scss" >
  .page-header {
    img {
      margin-top: 3em;
    }
  }
</style>
